<template>
  <main
    class="blank-aside-js page_bg_grey"
    :class="isOpenAside == 'true' ? 'blank-aside' : ''"
  >
    <p class="main-title weight-700">
      {{ $t('MANAGEMENT.COPY_VENDOR_PERMISSIONS') }}
    </p>
    <section class="sm-content">
      <div class="form-item">
        <label>{{ $t('MANAGE_SHANIV.CATEGORY') }}</label>
        <div class="m-t-5 m-b-15 icon-input">
          <input
            type="text"
            class="input"
            :placeholder="$t('REPORT_SALES.EVERYTHING')"
          />
          <img src="/assets/img/icons/search-light.svg" alt="" />
        </div>
      </div>
    </section>
    <section class="sm-content m-t-50">
      <div class="filter-block bdbr-1">
        <label for="" class="d-block weight-700 m-b-15">{{ $t('MANAGEMENT.SOURCE_PROVIDER') }}</label>
        <div class="d-flex justify-content-between form-group">
          <div class="form-item">
            <label>{{ $t('REPORT_SALES.SUPPLIER_ID') }}</label>
            <div class="m-t-5 m-b-15 icon-input">
              <input
                type="text"
                class="input"
                :placeholder="$t('REPORT_SALES.EVERYTHING')"
              />
              <img src="/assets/img/icons/search-light.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="sm-content m-t-50">
      <div class="filter-block bdbr-1">
        <label for="" class="d-block weight-700 m-b-15">{{ $t('MANAGEMENT.TARGET_PROVIDER') }}</label>
        <div class="d-flex justify-content-between form-group">
          <div class="form-item">
            <label>{{ $t('REPORT_SALES.SUPPLIER_ID') }}</label>
            <div class="m-t-5 m-b-15 icon-input">
              <input
                type="text"
                class="input"
                :placeholder="$t('REPORT_SALES.EVERYTHING')"
              />
              <img src="/assets/img/icons/search-light.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <button class="primary-btn save-btn m-t-50 m-b-40">
      {{ $t('COMMON.SAVE') }}
    </button>
  </main>
</template>

<script>
export default {
  name: 'SCProviderPermission',
  data() {
    return {
    }
  },
}
</script>

<style lang="scss" scoped>
.form-item {
  width: calc(33% - 30px);
}
.filter-block {
  padding: 10px 20px;
}
.save-btn {
  margin: 0 auto;
}
@media screen and (max-width: 900px) {
  .form-group {
    flex-wrap: wrap;
  }
  .form-item { 
    width: 100%;
  }
  .save-btn {
    max-width: 90%;
  }

}
</style>